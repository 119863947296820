import * as Yup from "yup";

const validationMessages = {
    required: "itemReturnForm.validationMessages.required",
    wholeNumbers: "itemReturnForm.validationMessages.wholeNumbers",
    wholeNumbersAK: "itemReturnForm.validationMessages.validAKCodeNumbers",
    email: "itemReturnForm.validationMessages.email",
    removalDateComparison: "itemReturnForm.validationMessages.removalDateComparison",
    installDateComparison: "itemReturnForm.validationMessages.installDateComparison",
    removedOdoComparison: "itemReturnForm.validationMessages.removedOdoComparison",
    installedOdoComparison: "itemReturnForm.validationMessages.installedOdoComparison",
};

const regexes = {
    wholeNumbers: /^\d+$/,
    email: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
};

export const ItemReturnSchema = Yup.object().shape({
    itemNumber: Yup.string()
        .required(validationMessages.required)
        .matches(regexes.wholeNumbers, { message: validationMessages.wholeNumbersAK }),
    documentNumber: Yup.string().required(validationMessages.required),
    purchaseDate: Yup.string().nullable().required(validationMessages.required),
    registeredOffice: Yup.string().required(validationMessages.required),
    itemInstalledDate: Yup.string().nullable()
        .required(validationMessages.required)
        .test("installDateComparison", validationMessages.installDateComparison,
            function (value) {
                const { itemRemovedDate } = this.parent;
                if (!itemRemovedDate || !value) {
                    return true;
                }
                return value <= itemRemovedDate;
            }
        ),
    itemRemovedDate: Yup.string().nullable()
        .required(validationMessages.required)
        .test("removalDateComparison", validationMessages.removalDateComparison,
            function (value) {
                const { itemInstalledDate } = this.parent;
                if (!itemInstalledDate || !value) {
                    return true;
                }
                return value >= itemInstalledDate;
            }
        ),
    itemInstalledOdometer: Yup.string()
        .required(validationMessages.required)
        .matches(regexes.wholeNumbers, { message: validationMessages.wholeNumbers })
        .test("installedOdoComparison", validationMessages.installedOdoComparison,
            function (value) {
                const { itemRemovedOdometer } = this.parent;
                if (!itemRemovedOdometer || !value) {
                    return true
                }
                return parseInt(value, 10) <= parseInt(itemRemovedOdometer, 10);
            }
        ),
    itemRemovedOdometer: Yup.string()
        .required(validationMessages.required)
        .matches(regexes.wholeNumbers, { message: validationMessages.wholeNumbers })
        .test("removedOdoComparison", validationMessages.removedOdoComparison,
            function (value) {
                const { itemInstalledOdometer } = this.parent;
                if (!itemInstalledOdometer || !value) {
                    return true
                }
                return parseInt(value, 10) >= parseInt(itemInstalledOdometer, 10);
            }
        ),
    vehicleIdentification: Yup.string().required(validationMessages.required),
    phone: Yup.string().required(validationMessages.required),
    additionalEmail: Yup.string()
        .nullable()
        .transform(value => (value === "" ? null : value))
        .matches(regexes.email, { message: validationMessages.email }),
    description: Yup.string().required(validationMessages.required),
    shopReturned: Yup.string().required(validationMessages.required),
});