import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { FormLabel, OrderList } from "../../Order/style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { fetchItemReturnItem } from "../../../core/redux/asyncActions/global";
import { ITEM_RETURN_LIST_PATH } from "../../../core/routes";
import { Wrapper, Title, Subtitle } from "../../../components/SideListening/style";
import { BackButton } from "../../OrderHistoryItem/style";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { StyledCircularProgress } from "../../../components/CircularProgress";
import { useMediaQuery, useTheme as useThemeMaterial } from "@material-ui/core";
import { PrintButton, ButtonWrapper } from "./style";
import { PrintOutlined } from "@material-ui/icons";


export const ReturnItemObj = () => {
    const { t } = useTranslation();
    const { id = "" } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.global.itemReturnItem);
    const [isLoading, setIsLoading] = useState(true)
    const themeMaterial = useThemeMaterial();
    const isMobileScreen = useMediaQuery(themeMaterial.breakpoints.down("xs"));
    const isTabletScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));
    const screenSize = isMobileScreen ? "phone" : isTabletScreen ? "tablet" : "desktop";

    const handleReturn = () => {
        if (history.location.pathname !== ITEM_RETURN_LIST_PATH) {
            history.push(ITEM_RETURN_LIST_PATH);
        }
    };    

    const getStatusText = (statusCode) => {
        switch (statusCode) {
            case 0:
                return t("itemReturnList.statuses.received");
            case 1:
                return t("itemReturnList.statuses.inReview");
            case 2:
                return t("itemReturnList.statuses.atTheManufacturer");
            case 3:
                return t("itemReturnList.statuses.responseReceived");
            case 4:
                return t("itemReturnList.statuses.accepted");
            case 5:
                return t("itemReturnList.statuses.rejected");
        }
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchItemReturnItem(id))
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, [dispatch, id]);

    const hasResponseData = (data) => {
        return Boolean(data && (data.response_text !== null || Object.keys(data.response_files).length > 0));
    };

    const renderGridItems = (screenSize) => {
        if (!data) return null;
        const itemsByScreenSize = {
            desktop: [
                {key: "status", value: getStatusText(data.status)},
                {key: "part", value: data.item_number},
                {key: "installDate", value: data.item_installed_date},
                {key: "submitDate", value: data.form_submitted_date},
                {key: "docNumber", value: data.document_number},
                {key: "removeDate", value: data.item_removed_date},
                {key: "lastUpdated", value: data.last_updated},
                {key: "vehicle", value: data.vehicle_identification},
                {key: "installOdo", value: data.item_installed_odometer},
                {key: "purchaseDate", value: data.purchase_date},
                {key: "shopReturned", value: data.shop_returned},
                {key: "removeOdo", value: data.item_removed_odometer},
            ],
            tablet: [
                {key: "part", value: data.item_number },
                {key: "installDate", value: data.item_installed_date},
                {key: "status", value: getStatusText(data.status)},
                {key: "removeDate", value: data.item_removed_date},
                {key: "submitDate", value: data.form_submitted_date},
                {key: "installOdo", value: data.item_installed_odometer},
                {key: "lastUpdated", value: data.last_updated},
                {key: "removeOdo", value: data.item_removed_odometer},
                {key: "purchaseDate", value: data.purchase_date},
                {key: "vehicle", value: data.vehicle_identification},
                {key: "docNumber", value: data.document_number},
                {key: "shopReturned", value: data.shop_returned},
            ],
            phone: [
                {key: "part", value: data.item_number},
                {key: "status", value: getStatusText(data.status)},
                {key: "submitDate", value: data.form_submitted_date},
                {key: "lastUpdated", value: data.last_updated},
                {key: "purchaseDate", value: data.purchase_date},
                {key: "docNumber", value: data.document_number},
                {key: "installDate", value: data.item_installed_date},
                {key: "removeDate", value: data.item_removed_date},
                {key: "installOdo", value: data.item_installed_odometer},
                {key: "removeOdo", value: data.item_removed_odometer},
                {key: "vehicle", value: data.vehicle_identification},
                {key: "shopReturned", value: data.shop_returned},
            ],
        };
        const items = itemsByScreenSize[screenSize];

        return items.map((item, index) => (
            <React.Fragment key={index}>
                <Grid item xl={1} md={2} sm={3} xs={5}>
                    <FormLabel>{t(`itemReturnItem.${item.key}`)}</FormLabel>
                </Grid>
                <Grid item xl={3} md={2} sm={3} xs={7}>
                    <FormLabel>{item.value}</FormLabel>
                </Grid>
            </React.Fragment>
        ));
    };

    return (
        <Wrapper>
            <ButtonWrapper>
                <BackButton onClick={handleReturn}>
                    <BackspaceIcon />
                    {t("productsList.orderHistory.return")}
                </BackButton>
                <PrintButton onClick={() => window.print()}>
                    <PrintOutlined/>
                </PrintButton>
            </ButtonWrapper>
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center" margin="40px">
                    <StyledCircularProgress disableShrink/>
                </Box>
            ) : (
                <OrderList>
                    {
                        data ? (
                            <>
                                <Title>
                                    {t("itemReturnList.tableTitles.returnApplication")}: {data.return_application_number && data.return_application_number.toUpperCase()}
                                </Title>
                                <Grid container spacing={1} style={{ marginTop: !isMobileScreen ? "20px" : "0px" }}>
                                    {renderGridItems(screenSize)}
                                </Grid>
                                <Box marginTop="20px">
                                    <Subtitle> {t("itemReturnItem.userInfo")} </Subtitle>
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={!isMobileScreen? 2 : 3}>
                                        <FormLabel>{t("itemReturnItem.user")}</FormLabel>
                                    </Grid>
                                    <Grid item xs={!isMobileScreen? 10 : 9}>
                                        <FormLabel>{data.user}</FormLabel>
                                    </Grid>
                                    {data.additional_email && (
                                        <>
                                            <Grid item xs={!isMobileScreen? 2 : 3}>
                                                <FormLabel>{t("itemReturnItem.additionalEmail")}</FormLabel>
                                            </Grid>
                                            <Grid item xs={!isMobileScreen? 10 : 9}>
                                                <FormLabel>{data.additional_email}</FormLabel>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={!isMobileScreen? 2 : 3}>
                                        <FormLabel>{t("itemReturnItem.phone")}</FormLabel>
                                    </Grid>
                                    <Grid item xs={!isMobileScreen? 10 : 9}>
                                        <FormLabel>{data.phone}</FormLabel>
                                    </Grid>
                                    <Grid item xs={!isMobileScreen? 2 : 3}>
                                        <FormLabel>{t("itemReturnItem.registeredOffice")}</FormLabel>
                                    </Grid>
                                    <Grid item xs={!isMobileScreen? 10 : 9}>
                                        <FormLabel>{data.registered_office}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Box marginTop="20px">
                                    <Subtitle>{t("itemReturnItem.description")}</Subtitle>
                                </Box>
                                <Grid container spacing={1}>
                                    {data.description && (
                                        data.description.includes("\n") ? (
                                            data.description.split("\n").map((paragraph, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <FormLabel>{paragraph}</FormLabel>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Grid item xs={12}>
                                                <FormLabel>{data.description}</FormLabel>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                                {Object.keys(data.return_application_files).length > 0 && (
                                    <>
                                        <Box marginTop="20px">
                                            <Subtitle>{t("itemReturnItem.attachments")}</Subtitle>
                                        </Box>
                                        <Grid container spacing={1}>
                                            {Object.entries(data.return_application_files).map(([filename, link]) => (
                                                <Grid item xs={12} key={filename}>
                                                    <FormLabel>
                                                        <a href={link} target="_blank" rel="noreferrer" style={{"text-decoration": "none"}}>
                                                            {filename}
                                                        </a>
                                                    </FormLabel>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                )}
                                {hasResponseData(data) && (
                                    <>
                                        <Title style={{ marginTop: "50px" }}>{t("itemReturnItem.response")}</Title>
                                        <Grid container spacing={1}>
                                            {data.response_text && (
                                                data.response_text.includes("\n") ? (
                                                    data.response_text.split("\n").map((paragraph, index) => (
                                                        <Grid item xs={12} key={index}>
                                                            <FormLabel>{paragraph}</FormLabel>
                                                        </Grid>
                                                    ))
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <FormLabel>{data.response_text}</FormLabel>
                                                    </Grid>
                                                )
                                            )}
                                        </Grid>
                                        {Object.keys(data.response_files).length > 0 && (
                                            <>
                                                <Box marginTop="20px">
                                                    <Subtitle>{t("itemReturnItem.attachments")}</Subtitle>
                                                </Box>
                                                <Grid container spacing={1}>
                                                    {Object.entries(data.response_files).map(([filename, link]) => (
                                                        <Grid item xs={12} key={filename}>
                                                            <FormLabel>
                                                                <a href={link} target="_blank" rel="noreferrer" style={{"text-decoration": "none"}}>
                                                                    {filename}
                                                                </a>
                                                            </FormLabel>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <Box display="flex" alignItems="center" justifyContent="center" margin="40px">
                                <Title>{t("itemReturnItem.error")}</Title>
                            </Box>
                        )
                    }
                </OrderList>
            )}
        </Wrapper>
    );
};