export const TOGGLE_MENU = "TOGGLE_MENU";
export const SHOW_MENU = "SHOW_MENU";
export const HIDE_MENU = "HIDE_MENU";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_SEARCH_VISIBLE = "SET_SEARCH_VISIBLE";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
export const ADD_CART = "ADD_CART";

export const CHANGE_VAT = "CHANGE_VAT";
export const CHANGE_DISCOUNT = "CHANGE_DISCOUNT";
export const SET_SELECTED_VENDORS = "SET_SELECTED_VENDORS";
export const SET_ONLY_IN_STOCK = "SET_ONLY_IN_STOCK";
export const SET_SELECTED_ITEM_CATEGORY = "SET_SELECTED_ITEM_CATEGORY";
export const SET_SELECTED_ITEM_GROUP = "SET_SELECTED_ITEM_GROUP";
export const SET_SELECTED_ITEM_MODEL = "SET_SELECTED_ITEM_MODEL";
export const SET_SELECTED_TAG = "SET_SELECTED_TAG";

export const FETCH_USER_DATA_REQUEST = "FETCH_USER_DATA_REQUEST";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_FAILURE = "FETCH_USER_DATA_FAILURE";

export const FETCH_MENU_PRODUCTS_REQUEST = "FETCH_MENU_PRODUCTS_REQUEST";
export const FETCH_MENU_PRODUCTS_SUCCESS = "FETCH_MENU_PRODUCTS_SUCCESS";
export const FETCH_MENU_PRODUCTS_FAILURE = "FETCH_MENU_PRODUCTS_FAILURE";

export const FETCH_VENDORS_REQUEST = "FETCH_VENDORS_REQUEST";
export const FETCH_VENDORS_SUCCESS = "FETCH_VENDORS_SUCCESS";
export const FETCH_VENDORS_FAILURE = "FETCH_VENDORS_FAILURE";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const FETCH_PUBLIC_PRODUCTS_REQUEST = "FETCH_PUBLIC_PRODUCTS_REQUEST";
export const FETCH_PUBLIC_PRODUCTS_SUCCESS = "FETCH_PUBLIC_PRODUCTS_SUCCESS";
export const FETCH_PUBLIC_PRODUCTS_FAILURE = "FETCH_PUBLIC_PRODUCTS_FAILURE";

export const UPDATE_PRICES_REQUEST = "UPDATE_PRICES_REQUEST";
export const UPDATE_PRICES_SUCCESS = "UPDATE_PRICES_SUCCESS";
export const UPDATE_PRICES_FAILURE = "UPDATE_PRICES_FAILURE";

export const UPDATE_PRICES_DETAILS_REQUEST = "UPDATE_PRICES_DETAILS_REQUEST";
export const UPDATE_PRICES_DETAILS_SUCCESS = "UPDATE_PRICES_DETAILS_SUCCESS";
export const UPDATE_PRICES_DETAILS_FAILURE = "UPDATE_PRICES_DETAILS_FAILURE";

export const UPDATE_CURRENT_PRODUCT_REQUEST = "UPDATE_CURRENT_PRODUCT_REQUEST";
export const UPDATE_CURRENT_PRODUCT_SUCCESS = "UPDATE_CURRENT_PRODUCT_SUCCESS";
export const UPDATE_CURRENT_PRODUCT_FAILURE = "UPDATE_CURRENT_PRODUCT_FAILURE";

export const CHANGE_COUNTRY_REQUEST = "CHANGE_COUNTRY_REQUEST";
export const CHANGE_COUNTRY_SUCCESS = "CHANGE_COUNTRY_SUCCESS";
export const CHANGE_COUNTRY_FAILURE = "CHANGE_COUNTRY_FAILURE";

export const FETCH_OEM_VEHICLES_REQUEST = "FETCH_OEM_VEHICLES_REQUEST";
export const FETCH_OEM_VEHICLES_SUCCESS = "FETCH_OEM_VEHICLES_SUCCESS";
export const FETCH_OEM_VEHICLES_FAILURE = "FETCH_OEM_VEHICLES_FAILURE";

export const FETCH_OEM_VEHICLES_DETAILS_REQUEST =
    "FETCH_OEM_VEHICLES_DETAILS_REQUEST";
export const FETCH_OEM_VEHICLES_DETAILS_SUCCESS =
    "FETCH_OEM_VEHICLES_DETAILS_SUCCESS";
export const FETCH_OEM_VEHICLES_DETAILS_FAILURE =
    "FETCH_OEM_VEHICLES_DETAILS_FAILURE";

export const FETCH_PRODUCT_DETAILS_REQUEST = "FETCH_PRODUCT_DETAILS_REQUEST";
export const FETCH_PRODUCT_DETAILS_SUCCESS = "FETCH_PRODUCT_DETAILS_SUCCESS";
export const FETCH_PRODUCT_DETAILS_FAILURE = "FETCH_PRODUCT_DETAILS_FAILURE";

export const FETCH_PRODUCT_DETAILS_VEHICLES_REQUEST =
    "FETCH_PRODUCT_DETAILS_VEHICLES_REQUEST";
export const FETCH_PRODUCT_DETAILS_VEHICLES_SUCCESS =
    "FETCH_PRODUCT_DETAILS_VEHICLES_SUCCESS";
export const FETCH_PRODUCT_DETAILS_VEHICLES_FAILURE =
    "FETCH_PRODUCT_DETAILS_VEHICLES_FAILURE";

export const FETCH_CUSTOMER_SEARCH_LIST_REQUEST =
    "FETCH_CUSTOMER_SEARCH_LIST_REQUEST";
export const FETCH_CUSTOMER_SEARCH_LIST_SUCCESS =
    "FETCH_CUSTOMER_SEARCH_LIST_SUCCESS";
export const FETCH_CUSTOMER_SEARCH_LIST_FAILURE =
    "FETCH_CUSTOMER_SEARCH_LIST_FAILURE";

export const SET_IMPERSONATED_COSTUMER_REQUEST = "SET_IMPERSONATED_COSTUMER_REQUEST";
export const SET_IMPERSONATED_COSTUMER_SUCCESS = "SET_IMPERSONATED_COSTUMER_SUCCESS";
export const SET_IMPERSONATED_COSTUMER_FAILURE = "SET_IMPERSONATED_COSTUMER_FAILURE";
export const CLEAR_IMPERSONATED_COSTUMER_SUCCESS = "CLEAR_IMPERSONATED_COSTUMER_SUCCESS";

export const FETCH_ORDER_HISTORY_LIST_REQUEST = "FETCH_ORDER_HISTORY_LIST_REQUEST";
export const FETCH_ORDER_HISTORY_LIST_SUCCESS = "FETCH_ORDER_HISTORY_LIST_SUCCESS";
export const FETCH_ORDER_HISTORY_LIST_FAILURE = "FETCH_ORDER_HISTORY_LIST_FAILURE";

export const FETCH_ORDER_HISTORY_ITEM_REQUEST = "FETCH_ORDER_HISTORY_ITEM_REQUEST";
export const FETCH_ORDER_HISTORY_ITEM_SUCCESS = "FETCH_ORDER_HISTORY_ITEM_SUCCESS";
export const FETCH_ORDER_HISTORY_ITEM_FAILURE = "FETCH_ORDER_HISTORY_ITEM_FAILURE";

export const CLEAR_PRODUCT_DETAILS = "CLEAR_PRODUCT_DETAILS";
export const UPDATE_PRICES_DETAILS_FILTERS = "UPDATE_PRICES_DETAILS_FILTERS";

export const FETCH_TOP_PRODUCTS_REQUEST = "FETCH_TOP_PRODUCTS_REQUEST";
export const FETCH_TOP_PRODUCTS_SUCCESS = "FETCH_TOP_PRODUCTS_SUCCESS";
export const FETCH_TOP_PRODUCTS_FAILURE = "FETCH_TOP_PRODUCTS_FAILURE";

export const SET_SEARCH_RESET_PAGE = "SET_SEARCH_RESET_PAGE";

export const FETCH_BY_CODE_SEARCH_LIST_REQUEST = "FETCH_BY_CODE_SEARCH_LIST_REQUEST";
export const FETCH_BY_CODE_SEARCH_LIST_SUCCESS = "FETCH_BY_CODE_SEARCH_LIST_SUCCESS";
export const FETCH_BY_CODE_SEARCH_LIST_FAILURE = "FETCH_BY_CODE_SEARCH_LIST_FAILURE";

export const CLEAR_BY_CODE_SEARCH_LIST = "CLEAR_BY_CODE_SEARCH_LIST";

export const FETCH_FAVOURITES_REQUEST = "FETCH_FAVOURITES_REQUEST";
export const FETCH_FAVOURITES_SUCCESS = "FETCH_FAVOURITES_SUCCESS";
export const FETCH_FAVOURITES_FAILURE = "FETCH_FAVOURITES_FAILURE";

export const SET_FAVOURITE_REQUEST = "SET_FAVOURITE_REQUEST";
export const SET_FAVOURITE_SUCCESS = "SET_FAVOURITE_SUCCESS";
export const SET_FAVOURITE_FAILURE = "SET_FAVOURITE_FAILURE";

export const REMOVE_FAVOURITE_REQUEST = "REMOVE_FAVOURITE_REQUEST";
export const REMOVE_FAVOURITE_SUCCESS = "REMOVE_FAVOURITE_SUCCESS";
export const REMOVE_FAVOURITE_FAILURE = "REMOVE_FAVOURITE_FAILURE";

export const FETCH_AVAILABILITY_NOTIFICATIONS_REQUEST = "FETCH_AVAILABILITY_NOTIFICATIONS_REQUEST";
export const FETCH_AVAILABILITY_NOTIFICATIONS_SUCCESS = "FETCH_AVAILABILITY_NOTIFICATIONS_SUCCESS";
export const FETCH_AVAILABILITY_NOTIFICATIONS_FAILURE = "FETCH_AVAILABILITY_NOTIFICATIONS_FAILURE";

export const SET_AVAILABILITY_NOTIFICATIONS_REQUEST = "SET_AVAILABILITY_NOTIFICATIONS_REQUEST";
export const SET_AVAILABILITY_NOTIFICATIONS_SUCCESS = "SET_AVAILABILITY_NOTIFICATIONS_SUCCESS";
export const SET_AVAILABILITY_NOTIFICATIONS_FAILURE = "SET_AVAILABILITY_NOTIFICATIONS_FAILURE";

export const REMOVE_AVAILABILITY_NOTIFICATIONS_REQUEST = "REMOVE_AVAILABILITY_NOTIFICATIONS_REQUEST";
export const REMOVE_AVAILABILITY_NOTIFICATIONS_SUCCESS = "REMOVE_AVAILABILITY_NOTIFICATIONS_SUCCESS";
export const REMOVE_AVAILABILITY_NOTIFICATIONS_FAILURE = "REMOVE_AVAILABILITY_NOTIFICATIONS_FAILURE";

export const FETCH_TAGS_REQUEST = "FETCH_TAGS_REQUEST";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";

export const ADD_TAG_REQUEST = "ADD_TAG_REQUEST";
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS";
export const ADD_TAG_FAILURE = "ADD_TAG_FAILURE";

export const DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILURE = "DELETE_TAG_FAILURE";

export const FETCH_TAG_ITEMS_REQUEST = "FETCH_TAG_ITEMS_REQUEST";
export const FETCH_TAG_ITEMS_SUCCESS = "FETCH_TAG_ITEMS_SUCCESS";
export const FETCH_TAG_ITEMS_FAILURE = "FETCH_TAG_ITEMS_FAILURE";

export const SET_TAG_ITEM_REQUEST = "SET_TAG_ITEM_REQUEST";
export const SET_TAG_ITEM_SUCCESS = "SET_TAG_ITEM_SUCCESS";
export const SET_TAG_ITEM_FAILURE = "SET_TAG_ITEM_FAILURE";

export const REMOVE_TAG_ITEM_REQUEST = "REMOVE_TAG_ITEM_REQUEST";
export const REMOVE_TAG_ITEM_SUCCESS = "REMOVE_TAG_ITEM_SUCCESS";
export const REMOVE_TAG_ITEM_FAILURE = "REMOVE_TAG_ITEM_FAILURE";

export const SET_COMMENT_REQUEST = "SET_COMMENT_REQUEST";
export const SET_COMMENT_SUCCESS = "SET_COMMENT_SUCCESS";
export const SET_COMMENT_FAILURE = "SET_COMMENT_FAILURE";

export const REMOVE_COMMENT_REQUEST = "REMOVE_COMMENT_REQUEST";
export const REMOVE_COMMENT_SUCCESS = "REMOVE_COMMENT_SUCCESS";
export const REMOVE_COMMENT_FAILURE = "REMOVE_COMMENT_FAILURE";

export const FETCH_YQ_CATALOG_REQUEST = "FETCH_YQ_CATALOG_REQUEST";
export const FETCH_YQ_CATALOG_SUCCESS = "FETCH_YQ_CATALOG_SUCCESS";
export const FETCH_YQ_CATALOG_FAILURE = "FETCH_YQ_CATALOG_FAILURE";

export const FETCH_YQ_PARAMS_REQUEST = "FETCH_YQ_PARAMS_REQUEST";
export const FETCH_YQ_PARAMS_SUCCESS = "FETCH_YQ_PARAMS_SUCCESS";
export const FETCH_YQ_PARAMS_FAILURE = "FETCH_YQ_PARAMS_FAILURE";

export const FETCH_YQ_LIST_VEHICLES_REQUEST = "FETCH_YQ_LIST_VEHICLES_REQUEST";
export const FETCH_YQ_LIST_VEHICLES_SUCCESS = "FETCH_YQ_LIST_VEHICLES_SUCCESS";
export const FETCH_YQ_LIST_VEHICLES_FAILURE = "FETCH_YQ_LIST_VEHICLES_FAILURE";

export const FETCH_YQ_FIND_VEHICLE_REQUEST = "FETCH_YQ_FIND_VEHICLE_REQUEST";
export const FETCH_YQ_FIND_VEHICLE_SUCCESS = "FETCH_YQ_FIND_VEHICLE_SUCCESS";
export const FETCH_YQ_FIND_VEHICLE_FAILURE = "FETCH_YQ_FIND_VEHICLE_FAILURE";

export const FETCH_YQ_OEM_VEHICLE_REQUEST = "FETCH_YQ_OEM_VEHICLE_REQUEST";
export const FETCH_YQ_OEM_VEHICLE_SUCCESS = "FETCH_YQ_OEM_VEHICLE_SUCCESS";
export const FETCH_YQ_OEM_VEHICLE_FAILURE = "FETCH_YQ_OEM_VEHICLE_FAILURE";

export const FETCH_YQ_OEM_VEHICLES_REQUEST = "FETCH_YQ_OEM_VEHICLES_REQUEST";
export const FETCH_YQ_OEM_VEHICLES_SUCCESS = "FETCH_YQ_OEM_VEHICLES_SUCCESS";
export const FETCH_YQ_OEM_VEHICLES_FAILURE = "FETCH_YQ_OEM_VEHICLES_FAILURE";

export const FETCH_NEW_PRODUCTS_REQUEST = "FETCH_NEW_PRODUCTS_REQUEST";
export const FETCH_NEW_PRODUCTS_SUCCESS = "FETCH_NEW_PRODUCTS_SUCCESS";
export const FETCH_NEW_PRODUCTS_FAILURE = "FETCH_NEW_PRODUCTS_FAILURE";

export const FETCH_PLATE_VEHICLES_REQUEST = "FETCH_PLATE_VEHICLES_REQUEST";
export const FETCH_PLATE_VEHICLES_SUCCESS = "FETCH_PLATE_VEHICLES_SUCCESS";
export const FETCH_PLATE_VEHICLES_FAILURE = "FETCH_PLATE_VEHICLES_FAILURE";

export const SHOW_NEW_PRODUCTS = "SHOW_NEW_PRODUCTS";

export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAILURE = "FETCH_INVOICES_FAILURE";

export const UPDATE_FORM_SUBMISSION_STATUS = "UPDATE_FORM_SUBMISSION_STATUS";

export const FETCH_ITEM_RETURN_LIST_REQUEST = "FETCH_ITEM_RETURN_LIST_REQUEST";
export const FETCH_ITEM_RETURN_LIST_SUCCESS = "FETCH_ITEM_RETURN_LIST_SUCCESS";
export const FETCH_ITEM_RETURN_LIST_FAILURE = "FETCH_ITEM_RETURN_LIST_FAILURE";

export const FETCH_ITEM_RETURN_ITEM_REQUEST = "FETCH_ITEM_RETURN_ITEM_REQUEST";
export const FETCH_ITEM_RETURN_ITEM_SUCCESS = "FETCH_ITEM_RETURN_ITEM_SUCCESS";
export const FETCH_ITEM_RETURN_ITEM_FAILURE = "FETCH_ITEM_RETURN_ITEM_FAILURE";

export const ITEM_RETURN_REQUEST = "ITEM_RETURN_REQUEST";
export const ITEM_RETURN_SUCCESS = "ITEM_RETURN_SUCCESS";
export const ITEM_RETURN_FAILURE = "ITEM_RETURN_FAILURE";

export const FETCH_RETURN_FORM_DATA_REQUEST = "FETCH_RETURN_FORM_DATA_REQUEST";
export const FETCH_RETURN_FORM_DATA_SUCCESS = "FETCH_RETURN_FORM_DATA_SUCCESS";
export const FETCH_RETURN_FORM_DATA_FAILURE = "FETCH_RETURN_FORM_DATA_FAILURE";

export const VERIFY_AK_CODE_REQUEST = "VERIFY_AK_CODE_REQUEST";
export const VERIFY_AK_CODE_SUCCESS = "VERIFY_AK_CODE_SUCCESS";
export const VERIFY_AK_CODE_FAILURE = "VERIFY_AK_CODE_FAILURE";

export const SET_LOGGING_IN = "SET_LOGGING_IN";
