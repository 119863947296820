import React from "react";

import Header from "../../components/Header";
import Wrapper from "../../components/Wrapper";
import { ReturnItemObj } from "./components/ReturnItemObj";

export const ReturnItemItem = () => {
    return (
        <div>
            <Header page="returnItemItem" />
            <Wrapper>
                <ReturnItemObj />
            </Wrapper>
        </div>
    );
};