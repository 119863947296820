import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import SettingsIcon from "@material-ui/icons/Settings";
import Notifications from "@material-ui/icons/Notifications";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme as useThemeMaterial } from "@material-ui/core/styles";
import { HOME_PATH, PUBLIC_SEARCH_PATH } from "../../core/routes";
import {
    setSearchVisible,
    setSearchQuery,
    setSelectedItemCategory,
    showMenu,
} from "../../core/redux/actions/global";
import { logout } from "../../core/redux/actions/login";

import UserMenu from "./Menu";
import Menu from "./Carts/menu";
import {
    DesktopHeaderSeparator,
    PublicDesktopHeaderSeparator,
    LogoWrapper,
    MenuButton,
    SearchButton,
    HeaderButton,
    ProductQuantity,
    AvailableItemCount,
    UserButton,
    UserDropdownCountry,
    UserDropdownTrigger,
    PublicLangButton,
    Wrapper,
    TagButton,
    LogoContainer,
    ButtonContainer
} from "./style";
import * as cartsAction from "../../core/redux/actions/carts";
import Search from "./Search";
import { Logo as Logoimg } from "../Logo";
import Favourites from "./Favorites";
import Notification from "./Notifications";
import { fetchFavourites, fetchAvailabilityNotifications, fetchTags } from "../../core/redux/asyncActions/global";
import TourPopup from "../Tour/TourPopup";
import Tags from "./Tags";


export default function Header({ page }) {
    const [userMenuRef, setUserMenuRef] = useState(null);
    const shoppingCartRef = useRef(null);
    const favButton = useRef(null);
    const notificationsAvailButton = useRef(null);
    const tagButton = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const themeMaterial = useThemeMaterial();
    const isMobileScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));

    const { lang, country, userData, searchVisible, favoritesList, availabilityNotificationsList, tagsList } = useSelector((state) => state.global);

    const countItems = useSelector((state) => state?.carts?.countItems || 0)

    const [userMenuVisible, setUserMenuVisible] = useState(false);
    const [basketDropdownVisible, setBasketDropdownVisible] = useState(
        false
    );
    const [favDropdownVisible, setFavDropdownVisible] = useState(false);
    const [notificationsDropdownVisible, setNotificationsDropdownVisible] = useState(false);
    const [tagDropdownVisible, setTagDropdownVisible] = useState(false);

    const handleLangClick = (e) => {
        e?.stopPropagation();
        if (e?.target === document.body) {
            return false;
        }
        setUserMenuVisible((prevState) => !prevState);
    };

    const handleBasketClick = (e) => {
        e?.stopPropagation();
        setBasketDropdownVisible((prevState) => !prevState);
        setUserMenuVisible(false);
        setTagDropdownVisible(false);
        setFavDropdownVisible(false);
        setNotificationsDropdownVisible(false);
    };

    const handleFavouritesClick = (e) => {
        e?.stopPropagation();
        dispatch(fetchFavourites());
        setFavDropdownVisible((prevState) => !prevState);
        setNotificationsDropdownVisible(false);
        setUserMenuVisible(false);
        setBasketDropdownVisible(false);
        setTagDropdownVisible(false);
    };

    const handleNotificationsClick = (e) => {
        e?.stopPropagation();
        dispatch(fetchAvailabilityNotifications());
        setNotificationsDropdownVisible((prevState) => !prevState);
        setFavDropdownVisible(false);
        setUserMenuVisible(false);
        setBasketDropdownVisible(false);
        setTagDropdownVisible(false);
    };

    const handleTagsClick = (e) => {
        e?.stopPropagation();
        dispatch(fetchTags());
        setTagDropdownVisible((prevState) => !prevState);
        setUserMenuVisible(false);
        setBasketDropdownVisible(false);
        setFavDropdownVisible(false);
        setNotificationsDropdownVisible(false);
    };

    const handleAvatarClick = (e) => {
        e?.stopPropagation();
        setUserMenuVisible((prevState) => !prevState);
        setBasketDropdownVisible(false);
        setFavDropdownVisible(false);
        setNotificationsDropdownVisible(false);
        setTagDropdownVisible(false);
    };

    const handleLogout = () => {
        localStorage.clear()
        dispatch(cartsAction.changeCountItems(1))
        dispatch(logout());
    };

    const handleLogoClick = () => {
        dispatch(setSearchQuery(""));
        dispatch(setSelectedItemCategory(null));
        if (history.location.pathname === PUBLIC_SEARCH_PATH) {
            history.push(PUBLIC_SEARCH_PATH);
        } else {
            if (history.location.pathname !== HOME_PATH) {
                history.push(HOME_PATH);
            }
        }
    };

    useEffect(() => {
        if (isMobileScreen) {
            dispatch(setSearchVisible(false));
        } else {
            dispatch(setSearchVisible(true));
        }
    }, [isMobileScreen, dispatch]);

    useEffect(() => {
        if (favoritesList?.count === 0) {
            setFavDropdownVisible(false);
        }
    }, [favoritesList?.count]);

    useEffect(() => {
        if (availabilityNotificationsList?.count === 0) {
            setNotificationsDropdownVisible(false);
        }
    }, [availabilityNotificationsList?.count]);

    function calculateStockItemCount(data) {
        let itemCount = 0;
      
        if (data?.results && Array.isArray(data.results)) {
            data.results.forEach((result) => {
                if (result && result.stock && typeof result.stock === "object" && result.stock.show_dot === true) {
                    itemCount += 1;
                }
            });
        }
      
        return itemCount;
    }

    const itemCount = calculateStockItemCount(availabilityNotificationsList);

    if (page === "register") {
        return (
            <Wrapper onClick={(e) => e.stopPropagation()}>
                <LogoContainer>
                    <Logoimg />
                </LogoContainer>
            </Wrapper>
        )
    };

    return (
        <Wrapper printHeader={page !== "returnItemItem"}>
            {((!searchVisible && isMobileScreen) || !isMobileScreen) &&
                <>
                    <MenuButton onClick={(e) => {
                        e?.stopPropagation();
                        dispatch(showMenu());
                    }}>
                        <MenuIcon />
                    </MenuButton>
                    <LogoWrapper onClick={handleLogoClick}>
                        <Logoimg isMobileScreen={isMobileScreen} />
                    </LogoWrapper>
                </>
            }
            {(page !== "publicSearch") &&
                <>
                    {searchVisible && <Search page="home" isMobileScreen={isMobileScreen} />}
                    {((!searchVisible && isMobileScreen) || !isMobileScreen) &&
                        <>
                            <ButtonContainer>
                                <SearchButton onClick={() => dispatch(setSearchVisible(true))} isSearchBoxVisible={searchVisible}>
                                    <SearchIcon fontSize="small" />
                                </SearchButton>
                                {Boolean(tagsList?.results?.length) &&
                                    <TagButton ref={tagButton} onClick={handleTagsClick}>
                                        <DirectionsCarOutlinedIcon fontSize="small" />
                                    </TagButton>}
                                {Boolean(availabilityNotificationsList?.count) &&
                                    <HeaderButton ref={notificationsAvailButton} onClick={handleNotificationsClick}>
                                        <ProductQuantity>{availabilityNotificationsList?.count}</ProductQuantity>
                                        <AvailableItemCount>{itemCount}</AvailableItemCount>
                                        <Notifications fontSize="small" />
                                    </HeaderButton>}
                                {Boolean(favoritesList?.count) &&
                                    <HeaderButton ref={favButton} onClick={handleFavouritesClick}>
                                        <ProductQuantity>{favoritesList?.count}</ProductQuantity>
                                        <SettingsIcon fontSize="small" />
                                    </HeaderButton>}
                                <HeaderButton ref={shoppingCartRef} autoMargin={favoritesList?.count === 0} onClick={handleBasketClick}>
                                    <ProductQuantity>{countItems}</ProductQuantity>
                                    <LocalMallOutlinedIcon fontSize="small" />
                                </HeaderButton>
                                <DesktopHeaderSeparator />
                                <UserButton ref={(ref) => setUserMenuRef(ref)} onClick={handleAvatarClick}>
                                    {userData?.impersonated_customer_name ? <PeopleAltRoundedIcon fontSize="small" /> : <PersonOutlineOutlinedIcon fontSize="small" />}
                                </UserButton>
                            </ButtonContainer>
                            <TourPopup
                                step={9}
                                anchorEl={shoppingCartRef.current}
                                placement={"right"}
                                margin="10px"
                            >
                                {t("appTour.shoppingCart")}
                            </TourPopup>
                            {userMenuRef && (
                                <TourPopup
                                    step={6}
                                    placement={isMobileScreen ? "bottom-end" : "left"}
                                    anchorEl={userMenuRef}
                                >
                                    {t('appTour.languageMenu')}
                                </TourPopup>
                            )}
                            <UserDropdownTrigger onClick={handleAvatarClick}>
                                {userData && userData?.name}
                                {userData?.impersonated_customer_name && (" / " + userData?.impersonated_customer_name)} {" "}
                                <UserDropdownCountry>
                                    ({country && country.substr(0, 3).toUpperCase()})
                                </UserDropdownCountry>{" "}
                                <ExpandMoreIcon />
                            </UserDropdownTrigger>
                            {userMenuVisible && (
                                <UserMenu
                                    overlayClick={handleAvatarClick}
                                    onLogout={handleLogout}
                                />
                            )}
                            {basketDropdownVisible && (
                                <Menu anchorEl={shoppingCartRef} overlayClick={handleBasketClick} closePopper={handleBasketClick} />
                            )}
                            {(favDropdownVisible && favoritesList?.count > 0) && (
                                <Favourites anchorEl={favButton} isMobileScreen={isMobileScreen} overlayClick={handleFavouritesClick} />
                            )}
                            {(notificationsDropdownVisible && availabilityNotificationsList?.count > 0) && (
                                <Notification anchorEl={notificationsAvailButton} isMobileScreen={isMobileScreen} overlayClick={handleNotificationsClick} />
                            )}
                            {(tagDropdownVisible && (tagsList?.results?.length)) ? (
                                <Tags anchorEl={tagButton} overlayClick={handleTagsClick} />
                            ) : ""}
                        </>
                    }
                </>}
            {(page === "publicSearch") &&
                <>
                    {searchVisible && <Search page={page} isMobileScreen={isMobileScreen} />}
                    {((!searchVisible && isMobileScreen) || !isMobileScreen) &&
                        <>
                            <SearchButton onClick={() => dispatch(setSearchVisible(true))}>
                                <SearchIcon />
                            </SearchButton>
                            <PublicDesktopHeaderSeparator />
                            <PublicLangButton onClick={handleLangClick}>
                                <UserDropdownCountry>
                                    {lang && lang.toUpperCase()}
                                </UserDropdownCountry>{" "}
                            </PublicLangButton>
                            <UserDropdownTrigger onClick={handleLangClick}>
                                <ExpandMoreIcon />
                            </UserDropdownTrigger>
                            {userMenuVisible && (
                                <UserMenu
                                    page={page}
                                    overlayClick={handleLangClick}
                                />
                            )}
                        </>
                    }
                </>
            }
        </Wrapper>
    );
}
