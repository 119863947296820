import styled from "styled-components";
import { theme } from "../../../core/styles/theme";

export const PrintButton = styled.button`
    background:${theme.colors.orange};
    border-radius: 2px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    color: white;

    &:hover {
        background: ${theme.colors.orangeDark};
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media print {
        display: none !important;
    }
`;