import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    ErrorMessage,
    ReturnFormGroup,
    Form,
    FormLabel,
    Input,
    SubmitButton,
    Title,
    Wrapper,
    FormLine,
    ChooseFilesButton,
    SelectedFiles,
    TotalFileSize,
    RemoveFile,
} from "../../../components/Form";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    FormStyledDatePicker,
    ReturnFormSelectControl,
    TextArea,
    ErrorBox,
    ProgressBlock,
    ItemDetails
} from "./style";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { theme } from "../../../core/styles/theme";
import DateFnsUtils from '@date-io/date-fns';
import { ItemReturnSchema } from "./validation";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { submitReturnForm } from "../../../core/redux/asyncActions/global";
import { fetchReturnFormData } from "../../../core/redux/asyncActions/global";
import { useHistory } from "react-router-dom";
import { ITEM_RETURN_SUCCESS, ITEM_RETURN_FAILURE } from "../../../core/redux/actionTypes/global";
import { ITEM_RETURN_LIST_PATH } from "../../../core/routes";
import { StyledCircularProgress } from "../../../components/CircularProgress";
import TourPopup from "../../../components/Tour/TourPopup";
import { verifyAKCode } from "../../../core/redux/asyncActions/global";
import { VERIFY_AK_CODE_SUCCESS } from "../../../core/redux/actionTypes/global";


export const ReturnForm = () => {
    const returnFormRef = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
        setError,
        clearErrors,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(ItemReturnSchema),
    });
    const history = useHistory();
    const [purchaseDate, setPurchaseDate] = useState(null);
    const [installedDate, setInstalledDate] = useState(null);
    const [removedDate, setRemovedDate] = useState(null);
    const [selectedShop, setSelectedShop] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [returnFormData, setReturnFormData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [fileSizeError, setFileSizeError] = useState("");
    const [isLoading, setIsLoading] = useState("")
    const [dataFetched, setDataFetched] = useState(false);
    const [validationAKResponse, setValidationAKResponse] = useState(null);

    const onSubmit = async (data) => {
        setIsLoading(true)
        const result = await dispatch(
            submitReturnForm({
                item_number: data.itemNumber,
                doc_number: data.documentNumber,
                purchase_date: data.purchaseDate,
                registered_office: data.registeredOffice,
                item_installed_date: data.itemInstalledDate,
                item_removed_date: data.itemRemovedDate,
                item_installed_odo: data.itemInstalledOdometer,
                item_removed_odo: data.itemRemovedOdometer,
                vehicle_identity: data.vehicleIdentification,
                phone: data.phone,
                additional_email: data.additionalEmail,
                description: data.description,
                shop_returned: data.shopReturned,
                files: data.files,
            })
        );
        if (result.type === ITEM_RETURN_SUCCESS) {
            setIsLoading(false)
            history.push(ITEM_RETURN_LIST_PATH);
        } else if (result.type === ITEM_RETURN_FAILURE) {
            setIsLoading(false)
            setErrorMessage(t("itemReturnForm.submitError"));
        }
    };

    const customTheme = createTheme({
        palette: {
            primary: {
                main: `${theme.colors.orange}`,
            },
        },
    })

    const handleFileChange = (e) => {
        const files = e.target.files;
        const newFiles = Array.from(files).map((file) => ({
            name: file.name,
            size: file.size,
        }));
        const newTotalSize = newFiles.reduce((acc, file) => acc + file.size, totalSize);
    
        if (newTotalSize > 20 * 1024 * 1024) {
            setFileSizeError(t("itemReturnForm.validationMessages.totalFileSize"));
            return;
        }

        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setTotalSize(newTotalSize);
        setFileSizeError("");
    };

    const handleRemoveFile = (index) => {
        setSelectedFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            const newTotalSize = newFiles.reduce((acc, file) => acc + file.size, 0);

            if (newTotalSize > 20 * 1024 * 1024) {
                setFileSizeError(t("itemReturnForm.validationMessages.totalFileSize"));
            } else {
                setFileSizeError("");
            }

            setTotalSize(newTotalSize);
            return newFiles;
        });
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleAKCodeVerification = async (event) => {
        const partNumber = event.target.value;
        try {
            const result = await dispatch(verifyAKCode(partNumber));
            if (result.type === VERIFY_AK_CODE_SUCCESS) {
                setValidationAKResponse(result.payload);
                clearErrors("itemNumber");
            } else {
                setValidationAKResponse(null);
                setError("itemNumber", {message: t("itemReturnForm.validationMessages.invalidAKCode")});
            }
        } catch (error) {
            setValidationAKResponse(null);
            setError("itemNumber", {message: t("itemReturnForm.validationMessages.akCodeValidationErr")});
        }
    };

    useEffect(() => {
        dispatch(fetchReturnFormData())
            .then((data) => {
                if (data.type === "FETCH_RETURN_FORM_DATA_FAILURE") {
                    setErrorMessage("Fetch from data failure");
                } else {
                    setReturnFormData(data.payload.locations);
                    setDataFetched(true);
                }
            })
    }, [dispatch]);
    
    return (
        <Wrapper>
            {
                errorMessage && errorMessage === "Fetch from data failure" ? (
                    <Title>{t("itemReturnForm.serverError")}</Title>
                ) : (
                    <>
                    <Title centerContent={true}>{t("itemReturnForm.title").toUpperCase()}</Title>
                    <Form
                        centerContent={true}
                        onSubmit={handleSubmit(onSubmit)}
                        encType="multipart/form-data"
                        ref={returnFormRef}
                        style={{ scrollMargin: "500px" }}
                    >
                        <ReturnFormGroup fullWidth={true}>
                            {errorMessage && (
                                <ErrorBox topMessage={true}>
                                    {errorMessage}
                                </ErrorBox>
                            )}
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.partNumber").toUpperCase()}
                                </FormLabel>
                                <Input
                                    type="text"
                                    name="itemNumber"
                                    placeholder={t("itemReturnForm.formFields.enterPartNumber")}
                                    id="item-number"
                                    ref={register}
                                    fullWidth={true}
                                    fullHeight={true}
                                    onBlur={handleAKCodeVerification}
                                    onChange={() => setValidationAKResponse(null)}
                                />
                                {errors.itemNumber && (
                                    <ErrorMessage>
                                        {t(errors.itemNumber.message)}
                                    </ErrorMessage>
                                )}
                                {validationAKResponse && (
                                    <ItemDetails>
                                        {validationAKResponse.vendor_item_code && (
                                            <div>
                                                <span style={{fontWeight: 500}}>
                                                    {t("productsList.tableHeader.oem")}:
                                                </span> {validationAKResponse.vendor_item_code}
                                            </div>
                                        )}
                                        {validationAKResponse.vendor && (
                                            <div>
                                                <span style={{fontWeight: 500}}>
                                                    {t("productsList.tableHeader.vendor")}:
                                                </span> {validationAKResponse.vendor}
                                            </div>
                                        )}
                                        {!validationAKResponse.vendor_item_code && !validationAKResponse.vendor && (
                                            <div>{t("itemReturnForm.formFields.validAKCode")}</div>
                                        )}
                                    </ItemDetails>
                                )}
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.docNumber").toUpperCase()}
                                </FormLabel>
                                <Input
                                    type="text"
                                    name="documentNumber"
                                    placeholder={t("itemReturnForm.formFields.enterDocNumber")}
                                    id="doc-number"
                                    ref={register}
                                    fullWidth={true}
                                    fullHeight={true}
                                />
                                {errors.documentNumber && (
                                    <ErrorMessage>
                                        {t(errors.documentNumber.message)}
                                    </ErrorMessage>
                                )}
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.purchaseDate").toUpperCase()}
                                </FormLabel>
                                <ThemeProvider theme={customTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Controller
                                            name="purchaseDate"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <div>
                                                    <FormStyledDatePicker
                                                        {...field}
                                                        emptyLabel={"dd.mm.yyyy"}
                                                        format="dd.MM.yyyy"
                                                        value={purchaseDate}
                                                        onChange={(date) => {
                                                            setPurchaseDate(date ? date.setHours(0, 0, 0, 0) : null);
                                                            setValue("purchaseDate", date ? date.setHours(0, 0, 0, 0) : null);
                                                            trigger("purchaseDate");
                                                        }}
                                                        variant="dialog"
                                                        minDate={new Date("2000-01-01")}
                                                        maxDate={Date.now()}
                                                        animateYearScrolling
                                                        maxDateMessage={t("itemReturnForm.validationMessages.maxDate")}
                                                        minDateMessage={t("itemReturnForm.validationMessages.minDate")}
                                                        invalidDateMessage={t("itemReturnForm.validationMessages.invalidDateFormat")}
                                                        clearable
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            name: "purchaseDate"
                                                        }}
                                                        okLabel={t("invoices.ok")}
                                                        clearLabel={t("invoices.clear")}
                                                        cancelLabel={t("invoices.cancel")}
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {errors.purchaseDate && (
                                        <ErrorMessage>
                                            {t(errors.purchaseDate.message)}
                                        </ErrorMessage>
                                    )}
                                </ThemeProvider>
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.legalAddr").toUpperCase()}
                                </FormLabel>
                                <Input
                                    type="text"
                                    name="registeredOffice"
                                    placeholder={t("itemReturnForm.formFields.enterLegalAddr")}
                                    id="legal-addr"
                                    ref={register}
                                    fullWidth={true}
                                    fullHeight={true}
                                />
                                {errors.registeredOffice && (
                                    <ErrorMessage>
                                        {t(errors.registeredOffice.message)}
                                    </ErrorMessage>
                                )}
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.itemInstalledDate").toUpperCase()}
                                </FormLabel>
                                <ThemeProvider theme={customTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Controller
                                            name="itemInstalledDate"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <div>
                                                    <FormStyledDatePicker
                                                        {...field}
                                                        emptyLabel={"dd.mm.yyyy"}
                                                        format="dd.MM.yyyy"
                                                        value={installedDate}
                                                        onChange={(date) => {
                                                            setInstalledDate(date ? date.setHours(0, 0, 0, 0) : null);
                                                            setValue("itemInstalledDate", date ? date.setHours(0, 0, 0, 0) : null);
                                                            trigger("itemInstalledDate");
                                                        }}
                                                        variant="dialog"
                                                        minDate={new Date("2000-01-01")}
                                                        maxDate={Date.now()}
                                                        animateYearScrolling
                                                        maxDateMessage={t("itemReturnForm.validationMessages.maxDate")}
                                                        minDateMessage={t("itemReturnForm.validationMessages.minDate")}
                                                        invalidDateMessage={t("itemReturnForm.validationMessages.invalidDateFormat")}
                                                        clearable
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            name: "itemInstalledDate"
                                                        }}
                                                        okLabel={t("invoices.ok")}
                                                        clearLabel={t("invoices.clear")}
                                                        cancelLabel={t("invoices.cancel")}
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {errors.itemInstalledDate && (
                                        <ErrorMessage>
                                            {t(errors.itemInstalledDate.message)}
                                        </ErrorMessage>
                                    )}
                                </ThemeProvider>
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.itemRemovedDate").toUpperCase()}
                                </FormLabel>
                                <ThemeProvider theme={customTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Controller
                                            name="itemRemovedDate"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <div>
                                                    <FormStyledDatePicker
                                                        {...field}
                                                        emptyLabel={"dd.mm.yyyy"}
                                                        format="dd.MM.yyyy"
                                                        value={removedDate}
                                                        onChange={(date) => {
                                                            setRemovedDate(date ? date.setHours(0, 0, 0, 0) : null);
                                                            setValue("itemRemovedDate", date ? date.setHours(0, 0, 0, 0) : null);
                                                            trigger("itemRemovedDate");
                                                        }}
                                                        variant="dialog"
                                                        minDate={new Date("2000-01-01")}
                                                        maxDate={Date.now()}
                                                        animateYearScrolling
                                                        maxDateMessage={t("itemReturnForm.validationMessages.maxDate")}
                                                        minDateMessage={t("itemReturnForm.validationMessages.minDate")}
                                                        invalidDateMessage={t("itemReturnForm.validationMessages.invalidDateFormat")}
                                                        clearable
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            name: "itemRemovedDate"
                                                        }}
                                                        okLabel={t("invoices.ok")}
                                                        clearLabel={t("invoices.clear")}
                                                        cancelLabel={t("invoices.cancel")}
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {errors.itemRemovedDate && (
                                        <ErrorMessage>
                                            {t(errors.itemRemovedDate.message)}
                                        </ErrorMessage>
                                    )}
                                </ThemeProvider>
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.itemInstalledOdo").toUpperCase()}
                                </FormLabel>
                                <Input
                                    type="text"
                                    name="itemInstalledOdometer"
                                    placeholder={t("itemReturnForm.formFields.enterOdo")}
                                    id="installed-odo"
                                    onChange={() => {
                                        trigger("itemInstalledOdometer");
                                    }}
                                    ref={register}
                                    fullWidth={true}
                                    fullHeight={true}
                                />
                                {errors.itemInstalledOdometer && (
                                    <ErrorMessage>
                                        {t(errors.itemInstalledOdometer.message)}
                                    </ErrorMessage>
                                )}
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.itemRemovedOdo").toUpperCase()}
                                </FormLabel>
                                <Input
                                    type="text"
                                    name="itemRemovedOdometer"
                                    placeholder={t("itemReturnForm.formFields.enterOdo")}
                                    id="removed-odo"
                                    onChange={() => {
                                        trigger("itemRemovedOdometer");
                                    }}
                                    ref={register}
                                    fullWidth={true}
                                    fullHeight={true}
                                />
                                {errors.itemRemovedOdometer && (
                                    <ErrorMessage>
                                        {t(errors.itemRemovedOdometer.message)}
                                    </ErrorMessage>
                                )}
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.vehicleIdentity").toUpperCase()}
                                </FormLabel>
                                <Input
                                    type="text"
                                    name="vehicleIdentification"
                                    placeholder={t("itemReturnForm.formFields.enterIdentity")}
                                    id="vehicle-id"
                                    ref={register}
                                    fullWidth={true}
                                    fullHeight={true}
                                />
                                {errors.vehicleIdentification && (
                                    <ErrorMessage>
                                        {t(errors.vehicleIdentification.message)}
                                    </ErrorMessage>
                                )}
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel htmlFor="phone" required>
                                    {t("itemReturnForm.formFields.phone").toUpperCase()}
                                </FormLabel>
                                <Input
                                    type="tel"
                                    name="phone"
                                    placeholder={t("itemReturnForm.formFields.enterPhone")}
                                    id="phone"
                                    ref={register}
                                    fullWidth={true}
                                    fullHeight={true}
                                />
                                {errors.phone && (
                                    <ErrorMessage>
                                        {t(errors.phone.message)}
                                    </ErrorMessage>
                                )}
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel>
                                    {t("itemReturnForm.formFields.email").toUpperCase()}
                                </FormLabel>
                                <Input
                                    type="email"
                                    name="additionalEmail"
                                    placeholder={t("itemReturnForm.formFields.enterEmail")}
                                    id="email"
                                    ref={register}
                                    fullWidth={true}
                                    fullHeight={true}
                                />
                                {errors.additionalEmail && (
                                    <ErrorMessage>
                                        {t(errors.additionalEmail.message)}
                                    </ErrorMessage>
                                )}
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel>
                                    {t("itemReturnForm.formFields.files").toUpperCase()}
                                </FormLabel>
                                <ChooseFilesButton htmlFor="files" >
                                    {t("itemReturnForm.formFields.chooseFiles").toUpperCase()}
                                </ChooseFilesButton>
                                <Input
                                    type="file"
                                    name="files"
                                    id="files"
                                    placeholder="asf"
                                    accept=".mov, .mp4, .jpg, .jpeg, .png, .pdf, .docx"
                                    multiple
                                    onChange={(e) => handleFileChange(e)}
                                    ref={register}
                                    fullWidth={true}
                                    fullHeight={true}
                                    style={{ display: "none" }}
                                />
                                <SelectedFiles>
                                    {selectedFiles.map((file, index) => (
                                        <div key={index}>
                                            {file.name} ({formatBytes(file.size)})
                                            <RemoveFile
                                                onClick={() => handleRemoveFile(index)}
                                            >
                                                {t("itemReturnForm.formFields.remove")
                                            }</RemoveFile>
                                        </div>
                                    ))}
                                </SelectedFiles>
                                {totalSize > 0 && (
                                    <TotalFileSize>
                                        {t("itemReturnForm.formFields.totalSize")} {formatBytes(totalSize)} <span style={{ fontWeight: "normal",  color: "#bcbcbc" }}>(max 20MB)</span>
                                    </TotalFileSize>
                                )}
                                {fileSizeError && (
                                    <ErrorMessage>
                                        {fileSizeError}
                                    </ErrorMessage>
                                )}
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.description").toUpperCase()}
                                </FormLabel>
                                <TextArea
                                    type="text"
                                    name="description"
                                    placeholder={t("itemReturnForm.formFields.provideDescription")}
                                    id="description"
                                    ref={register}
                                />
                                {errors.description && (
                                    <ErrorMessage>
                                        {t(errors.description.message)}
                                    </ErrorMessage>
                                )}
                                <ItemDetails>
                                    <div>- {t("itemReturnInstructions.itemDescription.leakage")}</div>
                                    <div>- {t("itemReturnInstructions.itemDescription.electronics")}</div>
                                </ItemDetails>
                            </FormLine>
                            <FormLine fullWidth={true}>
                                <FormLabel required>
                                    {t("itemReturnForm.formFields.shopReturned").toUpperCase()}
                                </FormLabel>
                                <ReturnFormSelectControl variant="outlined" size="small">
                                    <Controller
                                        name="shopReturned"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <div>
                                                <InputLabel id="shop-label">
                                                    {t("itemReturnForm.formFields.chooseShop")}
                                                </InputLabel>
                                                <Select
                                                    labelId="shop-label"
                                                    id="shop"
                                                    {...field}
                                                    onChange={(e) => {
                                                        setSelectedShop(e.target.value);
                                                        setValue("shopReturned", e.target.value);
                                                        trigger("shopReturned")
                                                    }}
                                                    value={selectedShop}
                                                    label={t("itemReturnForm.formFields.chooseShop")}
                                                    style={{ width: "100%" }}
                                                    >
                                                    {returnFormData.map((shop) => (
                                                        <MenuItem key={shop} value={shop}>
                                                            {shop}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        )}
                                    />
                                </ReturnFormSelectControl>
                                {errors.shopReturned && (
                                    <ErrorMessage>
                                        {t(errors.shopReturned.message)}
                                    </ErrorMessage>
                                )}
                            </FormLine>
                            {errorMessage && (
                                <ErrorBox>
                                    {errorMessage}
                                </ErrorBox>
                            )}
                            {isLoading &&
                                <ProgressBlock>
                                    <StyledCircularProgress />
                                </ProgressBlock>
                            }
                            <SubmitButton
                                type="submit"
                                returnForm={true}
                                disabled={isLoading || validationAKResponse === null}>
                                {t("itemReturnForm.submit").toUpperCase()}
                            </SubmitButton>
                        </ReturnFormGroup>
                    </Form>
                    </>
                )
            }
            {dataFetched && (
                <TourPopup
                    step={7}
                    placement="top-middle"
                    anchorEl={returnFormRef.current}
                    scroll="start"
                >
                    {t("appTour.returnForm")}
                </TourPopup>
            )}
        </Wrapper>
    );
};
